'use client';

// External
import Container from '@taskrabbit/meadow-web/lib/Container';
import dynamic from 'next/dynamic';
import Grid from '@taskrabbit/meadow-web/lib/Grid';
import Stack from '@taskrabbit/meadow-web/lib/Stack';
import Typography from '@taskrabbit/meadow-web/lib/Typography';

// Internal
import useIntl from '~hooks/useIntl';
import type { LocaleDrivenConfig } from '~lang/localeDrivenConfig';
import { localeDrivenConfig } from '~lang/localeDrivenConfig';
import Root, {
  FooterAnchorComp,
  FooterNextLinkComp,
  InvertedDividerComp,
  InvertedTypographyComp,
} from './SiteFooter.styled';
import type { LocalizedPathsKeys } from '~utils/localizedPaths';
import { getLocalizedPath } from '~utils/localizedPaths';

import AppStoreIcon from './AppStoreIcon';
import SocialIcon from './SocialIcon';

// Types
type LinkTypes<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

type IdKey = LinkTypes<LocaleDrivenConfig, string>;

export interface FooterLinkProps {
  link: FooterItem;
  locale: string;
}

export interface SocialItem {
  alt: string;
  id: IdKey;
  type: string;
}

export interface FooterItem {
  disableNextRouter?: boolean;
  external?: boolean;
  href?: string;
  locale?: string | false;
  messageId?: string;
  pathId?: LocalizedPathsKeys;
  text?: string;
  v3?: boolean;
}
export interface FooterItems {
  company: FooterItem[];
  discover: FooterItem[];
  legal: FooterItem[];
  social: SocialItem[];
  terms: FooterItem[];
}
export interface SiteFooterProps {
  footerItems?: FooterItems;
}

// Components
const AppStoreOneLink = dynamic(() => import('./AppStoreOneLink'), {
  ssr: false,
});

export const FooterLink = ({ link, locale: nextLocale }: FooterLinkProps) => {
  const { formatMessage } = useIntl();
  const { disableNextRouter, external, href, text, pathId, messageId } = link;

  let locale = link.locale;
  let rel, target;

  const url =
    pathId && nextLocale ? getLocalizedPath(nextLocale, pathId) : href;

  if (!url || (locale && locale !== nextLocale)) return null;

  if (external) {
    locale = false;
    rel = 'noreferrer';
    target = '_blank';
  }

  const linkText = messageId ? formatMessage({ id: messageId }) : text;

  if (disableNextRouter) {
    return (
      <FooterAnchorComp href={url} rel={rel} target={target}>
        {linkText}
      </FooterAnchorComp>
    );
  }

  return (
    <FooterNextLinkComp
      href={url}
      locale={locale}
      prefetch={false}
      rel={rel}
      target={target}
    >
      {linkText}
    </FooterNextLinkComp>
  );
};

const SiteFooter = ({ footerItems }: SiteFooterProps) => {
  const { locale, formatMessage } = useIntl();

  const company = footerItems?.company || [];
  const discover = footerItems?.discover || [];
  const legal = footerItems?.legal || [];
  const social = footerItems?.social || [];
  const terms = footerItems?.terms || [];

  return (
    <Root>
      <Container maxWidth="lg">
        <Stack direction="row" lineHeight={1} spacing={1} sx={{ mb: 2 }}>
          <Typography sx={{ color: '#b1b9c3' }}>
            {formatMessage({ id: 'footer.title.follow' })}
          </Typography>
          &nbsp;
          <Stack
            alignItems="center"
            direction="row"
            divider={<InvertedDividerComp flexItem orientation="vertical" />}
            spacing={1}
          >
            {social.map(({ alt, id, type }) => (
              <SocialIcon
                alt={alt}
                href={localeDrivenConfig(locale, id)}
                key={alt}
                type={type}
              />
            ))}
          </Stack>
        </Stack>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Stack>
              <Typography sx={{ color: '#b1b9c3' }}>
                {formatMessage({ id: 'footer.title.discover' })}
              </Typography>
              {discover.map((link) => (
                <FooterLink
                  key={link.href || link.pathId}
                  link={link}
                  locale={locale}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Stack>
              <Typography sx={{ color: '#b1b9c3' }}>
                {formatMessage({ id: 'footer.title.company' })}
              </Typography>
              {company.map((link) => (
                <FooterLink
                  key={link.href || link.pathId}
                  link={link}
                  locale={locale}
                />
              ))}
              {terms.length === 2 && (
                <Stack direction="row">
                  <FooterLink
                    key={terms[0].messageId}
                    link={terms[0]}
                    locale={locale}
                  />
                  <Typography sx={{ color: '#fff', fontWeight: 600 }}>
                    &nbsp;&amp;&nbsp;
                  </Typography>
                  <FooterLink
                    key={terms[1].messageId}
                    link={terms[1]}
                    locale={locale}
                  />
                </Stack>
              )}
              {legal.map((link) => (
                <FooterLink
                  key={link.href || link.pathId}
                  link={link}
                  locale={locale}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Typography sx={{ color: '#b1b9c3' }}>
              {formatMessage({ id: 'footer.title.download' })}
            </Typography>
            <Stack spacing={3}>
              <InvertedTypographyComp>
                {formatMessage({ id: 'footer.message.download' })}
              </InvertedTypographyComp>
              <Stack alignItems="center">
                <AppStoreOneLink
                  app="client"
                  data-behavior="app-link-clicked"
                  data-registered=""
                  data-source="footer"
                >
                  <AppStoreIcon
                    alt={formatMessage({ id: 'footer.apple.alt' })}
                    height={58}
                    locale={locale}
                    type="ios"
                    width={175}
                  />
                </AppStoreOneLink>
                <AppStoreOneLink
                  app="client"
                  data-behavior="app-link-clicked"
                  data-registered=""
                  data-source="footer"
                  rel="noreferrer"
                  target="_blank"
                >
                  <AppStoreIcon
                    alt={formatMessage({ id: 'footer.play.alt' })}
                    height={58}
                    locale={locale}
                    type="android"
                    width={175}
                  />
                </AppStoreOneLink>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default SiteFooter;
