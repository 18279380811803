// Examples: https://github.com/trpc/trpc/blob/next/examples

// External
import superjson from 'superjson';

import { httpBatchLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';

// Internal
import type { AppRouter } from '~server/trpc/router';

/** Get base URL where tRPC API endpoint is located */
const getBaseUrl = () => {
  if (typeof window !== 'undefined') return ''; // Browser should use relative URL

  /**
   * TODO: The method by which we deploy may require further changes here
   *
   * if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
   */

  return `http://localhost:${process.env.PORT ?? 4200}`; // Default to localhost
};

/** Set up tRPC & React Query */
export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      links: [httpBatchLink({ url: `${getBaseUrl()}/next-api/trpc` })],
      // tRPC merely wraps React Query, refer to its docs for config:
      // https://tanstack.com/query/v4/docs/reference/QueryClient
      queryClientConfig: {
        defaultOptions: {
          queries: {
            // Opt-in to refetching on per-query basis
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
          },
        },
      },
      transformer: superjson,
    };
  },
  // We use tRPC SSG helpers to prefetch queries in getStaticProps or getServerSideProps
  // https://trpc.io/docs/ssr
  ssr: false,
});

export default trpc;
